const CarIcon = ({ currentColor }) => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12687 6C5.22782 6 4.40303 6.50494 3.99263 7.30349L3.99781 7.29469L3.98281 7.32281C3.98606 7.31635 3.98933 7.30991 3.99263 7.30349L2.35438 10.0875C2.12265 10.4651 2 10.8992 2 11.3419V15.7481C2 16.4561 2.58385 17.04 3.29188 17.04H3.96406C4.19462 18.3975 5.37912 19.44 6.8 19.44C8.22088 19.44 9.40538 18.3975 9.63594 17.04H18.3641C18.5946 18.3975 19.7791 19.44 21.2 19.44C22.6209 19.44 23.8054 18.3975 24.0359 17.04H24.56C25.3498 17.04 26 16.3898 26 15.6V13.3866C26 12.2167 25.1483 11.212 23.9947 11.0194L19.9972 10.3528L16.6559 6.85312C16.2001 6.31214 15.5283 6 14.8212 6H6.12687ZM10.16 6.96H6.12687C5.58164 6.96 5.08551 7.26587 4.84063 7.75313L4.83312 7.76812L3.33125 10.32H10.16V6.96ZM14.8212 6.96H11.12V10.32H18.6378L15.9312 7.48312L15.9219 7.47188C15.6481 7.14694 15.246 6.96 14.8212 6.96ZM19.7197 11.28H2.96375C2.96339 11.2885 2.96269 11.2969 2.962 11.3053C2.961 11.3174 2.96 11.3296 2.96 11.3419V15.7481C2.96 15.9367 3.10326 16.08 3.29188 16.08H3.96406C4.19462 14.7225 5.37912 13.68 6.8 13.68C8.22088 13.68 9.40538 14.7225 9.63594 16.08H18.3641C18.5946 14.7225 19.7791 13.68 21.2 13.68C22.6209 13.68 23.8054 14.7225 24.0359 16.08H24.56C24.8262 16.08 25.04 15.8662 25.04 15.6V13.3866C25.04 12.6796 24.5344 12.0827 23.8372 11.9663L19.7197 11.28ZM8.72 16.56C8.72 15.4942 7.86578 14.64 6.8 14.64C5.73422 14.64 4.88 15.4942 4.88 16.56C4.88 17.6258 5.73422 18.48 6.8 18.48C7.86578 18.48 8.72 17.6258 8.72 16.56ZM23.12 16.56C23.12 15.4942 22.2658 14.64 21.2 14.64C20.1342 14.64 19.28 15.4942 19.28 16.56C19.28 17.6258 20.1342 18.48 21.2 18.48C22.2658 18.48 23.12 17.6258 23.12 16.56Z"
        fill={currentColor}
      />
    </svg>
  );
};

export default CarIcon;
