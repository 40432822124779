import styles from "./support.module.css";

function Support() {
  return (
    <div className={styles.support}>
      <div className={styles.support__container}>
        <label>Support</label>
      </div>
    </div>
  );
}

export default Support;
