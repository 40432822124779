const PeopleIcon = ({ currentColor }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.78813 10.7214C9.66851 10.7214 11.1983 9.14939 11.1983 7.21714C11.1983 5.2849 9.66851 3.71289 7.78813 3.71289C5.90774 3.71289 4.37793 5.2849 4.37793 7.21714C4.37793 9.14939 5.90774 10.7214 7.78813 10.7214ZM7.78813 4.32728C9.33887 4.32728 10.6004 5.62363 10.6004 7.21714C10.6004 8.81066 9.33887 10.107 7.78813 10.107C6.23738 10.107 4.97583 8.81066 4.97583 7.21714C4.97583 5.62363 6.23738 4.32728 7.78813 4.32728Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
      <path
        d="M7.78733 9.3679C8.42249 9.3679 8.97894 8.95114 9.17226 8.33122C9.22248 8.16964 9.13598 7.99679 8.97874 7.94498C8.82169 7.89398 8.65328 7.98246 8.60286 8.14383C8.48926 8.50857 8.16161 8.75371 7.78753 8.75371C7.41345 8.75371 7.0858 8.50857 6.9724 8.14363C6.92218 7.98205 6.75377 7.89235 6.59672 7.94457C6.43947 7.99618 6.35258 8.16902 6.403 8.33061C6.59553 8.95093 7.15197 9.3679 7.78733 9.3679Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
      <path
        d="M15.7522 9.74707C14.0153 9.74707 12.3846 10.623 11.3895 12.0901C11.3846 12.0975 11.3798 12.1051 11.3754 12.1129L11.2566 12.3259C10.301 11.6367 9.13867 11.2306 7.88528 11.2306C4.64011 11.2306 2 13.9439 2 17.2792C2 17.4488 2.13373 17.5864 2.29895 17.5864H13.4718C13.6368 17.5864 13.7708 17.4488 13.7708 17.2792C13.7708 16.6653 13.6807 16.0726 13.5145 15.5139H20.7657C20.9308 15.5139 21.0647 15.3763 21.0647 15.2067C21.0647 12.1962 18.6817 9.74707 15.7522 9.74707ZM2.60627 16.9722C2.76192 14.1182 5.07019 11.8452 7.88528 11.8452C10.7004 11.8452 13.0088 14.1184 13.1645 16.9722H2.60627ZM13.2954 14.8997C12.9409 14.0517 12.4024 13.3042 11.7327 12.708L11.8876 12.4305C12.7717 11.1348 14.2154 10.3615 15.7524 10.3615C18.2516 10.3615 20.3028 12.3705 20.4574 14.8997H13.2954Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
      <path
        d="M15.6655 9.35316C17.3701 9.35316 18.7569 7.92819 18.7569 6.17658C18.7569 4.42497 17.3701 3 15.6655 3C13.9609 3 12.5742 4.42497 12.5742 6.17658C12.5742 7.92819 13.9609 9.35316 15.6655 9.35316ZM15.6655 3.61439C17.0403 3.61439 18.159 4.7637 18.159 6.17658C18.159 7.58946 17.0405 8.73877 15.6655 8.73877C14.2906 8.73877 13.1721 7.58946 13.1721 6.17658C13.1721 4.7637 14.2906 3.61439 15.6655 3.61439Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
      <path
        d="M15.6642 8.13786C16.1728 8.13786 16.619 7.80384 16.7747 7.3066C16.8253 7.14501 16.7388 6.97196 16.5818 6.92015C16.4243 6.86813 16.2561 6.95701 16.2055 7.11839C16.1296 7.36066 15.9119 7.52348 15.664 7.52348C15.4161 7.52348 15.1984 7.36087 15.1227 7.1186C15.0723 6.95722 14.9057 6.86834 14.7466 6.91994C14.5896 6.97176 14.5029 7.14481 14.5535 7.30639C14.7092 7.80384 15.1554 8.13786 15.6642 8.13786Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default PeopleIcon;
