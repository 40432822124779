import styles from "./terms-and-conditions.module.css";

function TermsAndConditions() {
  return (
    <div className={styles.terms_and_conditions}>
      <div className={styles.terms_and_conditions__container}>
        <label>Terms and conditions</label>
      </div>
    </div>
  );
}

export default TermsAndConditions;
