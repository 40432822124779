import styles from "./settings.module.css";

function Settings() {
  return (
    <div className={styles.settings}>
      <div className={styles.settings__container}>
        <label>Settings</label>
      </div>
    </div>
  );
}

export default Settings;
