const CurrentOrderIcon = ({ currentColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8918 5.70288H20.5552C20.6497 5.70288 20.7404 5.66533 20.8072 5.59848C20.8741 5.53164 20.9116 5.44098 20.9116 5.34644C20.9116 5.25191 20.8741 5.16125 20.8072 5.0944C20.7404 5.02756 20.6497 4.99001 20.5552 4.99001H18.1789C18.0844 4.99001 17.9937 5.02756 17.9269 5.0944C17.8601 5.16125 17.8225 5.25191 17.8225 5.34644V7.72268C17.8225 7.81721 17.8601 7.90787 17.9269 7.97472C17.9937 8.04156 18.0844 8.07912 18.1789 8.07912C18.2735 8.07912 18.3641 8.04156 18.431 7.97472C18.4978 7.90787 18.5354 7.81721 18.5354 7.72268V6.3706C19.9222 7.9636 20.6823 10.0066 20.674 12.1187C20.674 13.8576 20.1583 15.5575 19.1923 17.0033C18.2262 18.4492 16.853 19.5761 15.2465 20.2415C13.6399 20.907 11.8722 21.0811 10.1667 20.7419C8.46116 20.4026 6.89456 19.5653 5.66497 18.3357C4.43537 17.1061 3.59801 15.5395 3.25877 13.834C2.91952 12.1285 3.09363 10.3607 3.75909 8.75414C4.42454 7.14759 5.55144 5.77446 6.99729 4.80837C8.44314 3.84229 10.143 3.32664 11.8819 3.32664C11.9764 3.32664 12.0671 3.28909 12.1339 3.22224C12.2008 3.1554 12.2383 3.06474 12.2383 2.97021C12.2383 2.87567 12.2008 2.78501 12.1339 2.71817C12.0671 2.65132 11.9764 2.61377 11.8819 2.61377C10.002 2.61377 8.16432 3.17122 6.60124 4.21564C5.03816 5.26006 3.81989 6.74453 3.10048 8.48133C2.38107 10.2181 2.19284 12.1293 2.55959 13.973C2.92634 15.8168 3.8316 17.5104 5.16089 18.8397C6.49018 20.169 8.1838 21.0743 10.0276 21.441C11.8714 21.8078 13.7825 21.6196 15.5193 20.9002C17.2561 20.1807 18.7406 18.9625 19.785 17.3994C20.8294 15.8363 21.3869 13.9986 21.3869 12.1187C21.3956 9.74169 20.5042 7.44947 18.8918 5.70288Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
      <path
        d="M5.46484 12.2378C5.46484 13.5302 5.84809 14.7936 6.56613 15.8682C7.28417 16.9429 8.30474 17.7804 9.49879 18.275C10.6928 18.7696 12.0067 18.899 13.2743 18.6469C14.5419 18.3947 15.7063 17.7724 16.6202 16.8585C17.5341 15.9446 18.1564 14.7802 18.4086 13.5126C18.6607 12.245 18.5313 10.9311 18.0367 9.73707C17.5421 8.54302 16.7046 7.52245 15.63 6.80441C14.5553 6.08638 13.2919 5.70312 11.9995 5.70312C10.267 5.70501 8.60596 6.39409 7.38088 7.61917C6.15581 8.84424 5.46673 10.5053 5.46484 12.2378ZM17.8213 12.2378C17.8213 13.3892 17.4798 14.5148 16.8401 15.4722C16.2004 16.4296 15.2912 17.1758 14.2274 17.6164C13.1636 18.057 11.993 18.1723 10.8637 17.9477C9.73441 17.7231 8.69707 17.1686 7.88288 16.3544C7.06868 15.5402 6.51421 14.5029 6.28958 13.3736C6.06494 12.2442 6.18024 11.0737 6.62087 10.0099C7.06151 8.94609 7.8077 8.03685 8.76509 7.39714C9.72248 6.75744 10.8481 6.416 11.9995 6.416C13.543 6.41788 15.0226 7.03185 16.114 8.12324C17.2054 9.21463 17.8194 10.6943 17.8213 12.2378Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
      <path
        d="M11.6445 8.19823V12.2378C11.6446 12.3083 11.6655 12.3771 11.7047 12.4357C11.7439 12.4943 11.7995 12.5399 11.8646 12.5668C11.9297 12.5938 12.0013 12.6009 12.0704 12.5871C12.1395 12.5734 12.203 12.5395 12.2528 12.4897L15.342 9.40061C15.4049 9.33304 15.4392 9.24367 15.4376 9.15133C15.4359 9.05899 15.3985 8.97089 15.3332 8.90558C15.2679 8.84028 15.1798 8.80287 15.0875 8.80124C14.9951 8.79961 14.9058 8.83389 14.8382 8.89685L12.3574 11.3776V8.19823C12.3574 8.1037 12.3198 8.01304 12.253 7.94619C12.1862 7.87935 12.0955 7.8418 12.001 7.8418C11.9064 7.8418 11.8158 7.87935 11.7489 7.94619C11.6821 8.01304 11.6445 8.1037 11.6445 8.19823Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default CurrentOrderIcon;
