const CardIcon = ({ currentColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3761 4.66797H2.54016C1.5876 4.66797 0.8125 5.44307 0.8125 6.39563V7.55027V10.0385V17.5195C0.8125 18.472 1.5876 19.2469 2.54016 19.2469H21.3761C22.3286 19.2469 23.1035 18.472 23.1035 17.5195V10.0385V7.55027V6.39563C23.1035 5.44307 22.3286 4.66797 21.3761 4.66797ZM1.52973 6.39563C1.52997 5.8384 1.98293 5.38544 2.53992 5.38544H21.3758C21.9328 5.38544 22.3858 5.83864 22.3858 6.39563V7.19154H1.52973V6.39563ZM22.386 17.5192C22.386 18.076 21.9328 18.5292 21.3761 18.5292H2.54016C1.98317 18.5292 1.52997 18.076 1.52997 17.5192V10.3969H22.386V17.5192ZM22.386 9.67948H1.52997V7.90877H22.386V9.67948Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
      <path
        d="M4.27984 17.1153C4.96981 17.1153 5.53087 16.5545 5.53087 15.8653C5.53087 15.1756 4.96981 14.6143 4.27984 14.6143C3.59036 14.6143 3.0293 15.1756 3.0293 15.8653C3.0293 16.5545 3.59036 17.1153 4.27984 17.1153ZM4.27984 15.3317C4.574 15.3317 4.8134 15.5711 4.8134 15.8653C4.8134 16.159 4.574 16.3979 4.27984 16.3979C3.98592 16.3979 3.74676 16.159 3.74676 15.8653C3.74676 15.5711 3.98592 15.3317 4.27984 15.3317Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
      <path
        d="M6.95444 16.2663H17.0621C17.2604 16.2663 17.4209 16.1058 17.4209 15.9076C17.4209 15.7093 17.2604 15.5488 17.0621 15.5488H6.95444C6.75618 15.5488 6.5957 15.7093 6.5957 15.9076C6.5957 16.1058 6.75618 16.2663 6.95444 16.2663Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CardIcon;
